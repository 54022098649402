<template>
  <div class="page">
    <div class="dkzc-box" v-loading="loading">
      <div class="title">卫生法规</div>
    </div>

    <div class="content">
      <div
        @click="getHomeId(item.id)"
        v-for="item in content"
        :key="item.id"
        class="content-group"
      >
        <span class="group-title">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [],
      loading: false,
    };
  },
  created() {
    // this.getHomeGet_home();
    this.getHome();
  },

  methods: {
    // async getHomeGet_home() {
    //   this.loading = true;
    //   let { code, data } = await this.$newsApi.getHomeGet_home({ type: 1 });
    //   this.loading = false;
    //   if (code == 200) {
    //     this.content = data;
    //   }
    // },

    // 卫生法规标题
    async getHome() {
      this.loading = true;
      let res = await this.$newsApi.getHome({ type: 1 });
      this.loading = false;
      this.content = res.data;
    },

    // 获取点击的id
    getHomeId(id) {
      this.$router.push({
        path: "/index-examSettingPolicy-info?",
        query: { id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  background: #f2f2f2;
  > .dkzc-box {
    // display: flex;
    // flex-direction: row;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 42px;
    }
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 70px 20px 70px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; //让自动分来
    background: #ffffff;
    border-radius: 4px;
    font-size: 24px;
    .content-group {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      color: #8f8f8f;
      font-size: 20px;
      width: 500px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 0px #efecec;
      border-radius: 10px;
      padding: 18px 40px;
      box-sizing: border-box;
      margin-bottom: 30px;
      position: relative;
      border: 1px solid #1cd6b9;
      ::after {
        content: "";
        width: 3px;
        height: 100%;
        position: absolute;
        background: #1cd6b9;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
      > .group-title {
        color: #3aa5ea;
      }
      > .time {
        font-size: 14px;
        margin-top: 16px;
        .minute {
          color: #333333;
          margin-right: 30px;
        }
      }
    }
  }
}
</style>
